import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { InfoModule } from './info/info.module';
import { CodigoConductaComponent } from './codigo-conducta/codigo-conducta.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path:'codigoconducta', component: CodigoConductaComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  
  { path: '**', component: NotfoundComponent }


];

@NgModule({
  imports: [ 
    RouterModule.forRoot(routes), 
    InfoModule 
  ],
  exports : [ RouterModule ]
})
export class AppRoutingModule { }
